import { LoginPage } from "@/components/auth";
import { Layout } from "@/components/layouts";

const NextLoginPage = () => (
  <Layout title="Sign in">
    <LoginPage />
  </Layout>
);

export default NextLoginPage;
